import { Box, Flex, Loader, Menu, ScrollArea, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBell, IconCalendar } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { NotificationApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";

type Notification = {
  id: string;
  body: string;
  createdAt: string;
  notifyStatus: "SUCCESS" | "FAILED";
  objectId: string;
  objectType: string;
  seen: boolean;
  title: string;
};

const Notifications = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  const fetchNotifications = async (pageNumber: number, isFirstLoad = false) => {
    if (loading || (!hasMore && !isFirstLoad)) return;
    setLoading(true);

    try {
      const res = await NotificationApi.list({ offset: { page: pageNumber, limit: 10 }, filter: "" });

      setNotifications((prev) => (isFirstLoad ? res.rows : [...prev, ...res.rows]));
      setPage(pageNumber + 1);
      setHasMore(res.rows.length > 0);
    } catch (error) {
      console.error("Failed to fetch notifications", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        fetchNotifications(page);
      }
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    setOpened(isOpen);
    if (isOpen) {
      setPage(1);
      setHasMore(true);
      setNotifications([]);
      fetchNotifications(1, true);
    }
  };

  const handleNotification = async (notification: any) => {
    if (!notification.seen) {
      try {
        await NotificationApi.seen(notification.id);
        setNotifications((prev) => prev.map((n) => (n.id === notification.id ? { ...n, seen: true } : n)));
      } catch (error) {
        console.error("Failed to mark notification as seen", error);
      }
    }
  };

  return (
    <Menu
      width={320}
      shadow="md"
      transitionProps={{ duration: 150, exitDuration: 150, transition: "fade" }}
      opened={opened}
      onChange={handleOpenChange}
      position="bottom-start"
      styles={{ dropdown: { border: "1px solid #15aabf" } }}>
      <Menu.Target>
        <UnstyledButton className={classes.notificationTarget} style={{ backgroundColor: opened ? "#108898" : "#15aabf" }}>
          <IconBell />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Text className={classes.dropdownTitle} size="sm" fw={500}>
          Мэдэгдлүүд
        </Text>
        <ScrollArea h={450} viewportRef={scrollRef} onScrollCapture={handleScroll}>
          {notifications.length < 0 && !loading ? (
            <Flex justify="center" align="center" h={200}>
              <Text size="sm" c="gray">
                Одоогоор мэдэгдэл алга
              </Text>
            </Flex>
          ) : (
            notifications.map((notification) => (
              <UnstyledButton
                key={notification.id}
                className={classes.notification}
                onClick={() => handleNotification(notification)}
                style={{ background: !notification.seen ? "#e7f6f8" : "transparent" }}>
                <Box style={{ flex: 1 }}>
                  <Text size="sm">{notification.title}</Text>
                  <Text size="sm">{notification.body}</Text>
                  <Flex align="center" justify="flex-end" gap="xs">
                    <IconCalendar size="18" color="#15aabf" />
                    <Text size="sm">{dateTimeFormat(notification.createdAt)}</Text>
                  </Flex>
                </Box>
                {!notification.seen ? <Box className={classes.unseen}></Box> : <Box style={{ width: 10 }}></Box>}
              </UnstyledButton>
            ))
          )}

          {loading && (
            <Flex justify="center" mt="sm">
              <Loader size="sm" />
            </Flex>
          )}
        </ScrollArea>
      </Menu.Dropdown>
    </Menu>
  );
};

export default Notifications;

const useStyles = createStyles(() => ({
  notificationTarget: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#e3dcff",
    color: "#fff",
    transition: "background 150ms linear",
    "&:hover": {
      background: "#108898 !important",
    },
  },
  notification: {
    width: "100%",
    padding: 10,
    borderBottom: "1px solid #ececec",
    display: "flex",
    alignItems: "center",
    gap: 10,
    transition: "background 150ms linear",
    borderRadius: 5,
    "&:hover": {
      background: "#f8f8f8 !important",
    },
    "&:last-child": {
      borderBottom: 0,
    },
  },
  unseen: {
    width: 10,
    height: 10,
    borderRadius: "100%",
    background: "#7950f2",
  },
  dropdownTitle: {
    padding: "0.5rem",
    borderBottom: "1px solid #eee",
  },
}));
