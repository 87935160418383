import { ActionIcon, Badge, Table as BaseTable, Box, Button, Divider, Flex, Grid, Group, LoadingOverlay, Modal, Select, Text, Tooltip } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { useDebouncedValue } from "@mantine/hooks";
import {
  IconCalendarDue,
  IconChecks,
  IconChevronDown,
  IconClearAll,
  IconCreditCard,
  IconEdit,
  IconExclamationMark,
  IconEyeCheck,
  IconFileText,
  IconInfoSquare,
} from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import { OrderApi } from "../../apis";
import { BuyerSalesDynamicConfirmForm } from "../../components/buyer-sales/confirm-form";
import { BuyerSalesPaymentForm } from "../../components/buyer-sales/payment-form";
import { BuyerSalesDynamicReviewForm } from "../../components/buyer-sales/review-form";
import { PageLayout } from "../../components/layout";
import { CustomTable, ITableRef } from "../../components/table/customTable";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IOrder } from "../../interfaces/IOrder";
import { IPermissions } from "../../interfaces/IPermissions";
import { tugrug } from "../../utils";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { renderName } from "../../utils/render-name";

export function BuyerSalesList() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const breadcrumbs = useBreadcrumb();
  const ref = React.useRef<ITableRef>(null);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { orderStatus, paymentStatus, orderPurchaseTypes, deliveryNoteStatus, invoiceStatus, paymentTermConditions } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [queryBusiness] = React.useState<string>("");
  const [orderType, setOrderType] = React.useState("");
  const [debouncedOrderType] = useDebouncedValue(orderType, 600);
  const [business, setBusiness] = React.useState("");
  const [debouncedBusiness] = useDebouncedValue(business, 600);
  const [invoiceType, setInvoiceType] = React.useState("");
  const [debouncedInvoiceType] = useDebouncedValue(invoiceType, 600);
  const [paymentType, setPaymentType] = React.useState("");
  const [debouncedPaymentType] = useDebouncedValue(paymentType, 600);
  const [paymentTermType, setPaymentTermType] = React.useState("");
  const [debouncedPaymentTermType] = useDebouncedValue(paymentTermType, 600);
  const [startDate, setStartDate] = React.useState<any>("");
  const [debouncedStartDate] = useDebouncedValue(startDate, 600);
  const [endDate, setEndDate] = React.useState<any>("");
  const [debouncedEndDate] = useDebouncedValue(endDate, 600);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [action, setAction] = React.useState<string[]>([]);

  const onClick = (key: any, record: any) => {
    switch (key) {
      case "edit": {
        navigate(`/buyer-sales/edit/${record?.id}`);
        break;
      }
      case "detail": {
        navigate(`/buyer-sales/detail/${record?.id}`);
        break;
      }
      case "payment": {
        setAction(["PAYMENT", record as any]);
        break;
      }
      case "cancel": {
        Dialog.confirm("Та борлуулалтын захиалгыг цуцлах гэж байна итгэлтэй байна уу?", async (key) => {
          switch (key) {
            case "confirm": {
              setIsLoading(true);
              try {
                let res = await OrderApi.cancel(record?.id);
                if (res.success) {
                  setTimeout(() => {
                    ref.current?.reload();
                  }, 200);
                }
                message.success("Амжилттай хүргэлтийн нөхцөлийг устгалаа!");
              } catch (err) {
                message.error((err as HttpHandler)?.message!);
              }
              setIsLoading(false);
              break;
            }

            default:
          }
        });
        break;
      }
      case "reviewed": {
        setAction(["reviewed", record as any]);
        break;
      }
      case "confirm": {
        setAction(["confirm", record as any, "confirm"]);
        break;
      }
      case "reject": {
        setAction(["reject", record as any, "reject"]);
        break;
      }

      default:
    }
  };

  const reload = async () => {
    ref.current?.reload();
  };

  const { data: businessData } = useSwr(`/ord/order/business_select/data/${queryBusiness}`, async () => {
    if ((user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0) {
      try {
        let res = await OrderApi.business({ query: queryBusiness, limit: "" });
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const onClear = () => {
    setOrderType("");
    setBusiness("");
    setInvoiceType("");
    setPaymentType("");
    setPaymentTermType("");
    setStartDate("");
    setEndDate("");
  };

  const order = async () => {
    setIsLoading(true);
    try {
      let res = await OrderApi.list({
        filter: {
          type: "",
          orderStatus: "",
          businessId: "",
          startDate: "",
          endDate: "",
          invoiceStatus: "",
          paymentStatus: "",
          paymentTermCondition: "",
          excel: `${true}`,
        },
        offset: {
          limit: 20,
          page: 1,
        },
      });

      window.open(res.excelUrl as string, "_blank");
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setIsLoading(false);
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <>
      <PageLayout
        title="Худалдан авалтын жагсаалт"
        subTitle="Бараа бүтээгдэхүүн ажил үйлчилгээ нийлүүлэгчдийн жагсаалт"
        breadcrumb={breadcrumbs}
        extra={[
          <Flex key={1} gap="md" align="center">
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isCreate).length > 0 && (
              <Button
                onClick={() => {
                  navigate("/buyer-sales/new");
                }}
                size="sm">
                Шинэ захиалга
              </Button>
            )}
            <Tooltip label="Файл" color="dark" position="bottom" withArrow={false} onClick={() => order()}>
              <ActionIcon variant="outline" size="lg">
                <IconFileText />
              </ActionIcon>
            </Tooltip>
          </Flex>,
        ]}>
        <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
          <Box
            sx={(theme) => ({
              background: theme.white,
              borderRadius: 8,
              border: `1px solid ${theme.colors.gray[2]}`,
              padding: "16px 20px",
              height: "100%",
              minHeight: "60vh",
            })}
            w={"100%"}>
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Select
                  data={[{ code: "", sentName: "Бүгд" }, ...(orderStatus || [])]?.map((item: any) => {
                    return {
                      label: item.sentName,
                      value: item.code,
                    };
                  })}
                  placeholder="сонгох"
                  leftSectionWidth={120}
                  onChange={(e: string | null) => {
                    if (e) setOrderType(e);
                  }}
                  value={orderType}
                  style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                  rightSection={<IconChevronDown size="1rem" stroke={1} />}
                  clearable
                  leftSection={
                    <Group w={"calc(100% - 20px)"} align="center">
                      <Text size="sm" fw={450} c="#44566C">
                        {"SO статус"}
                      </Text>
                      <Text>:</Text>
                    </Group>
                  }
                />
              </Grid.Col>
              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Select
                    data={(businessData || [])?.map((item: any) => {
                      return {
                        label: item.profileName,
                        value: item.id,
                      };
                    })}
                    placeholder="сонгох"
                    leftSectionWidth={150}
                    onChange={(e: string | null) => {
                      if (e) setBusiness(e);
                    }}
                    value={business}
                    style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                    rightSection={<IconChevronDown size="1rem" stroke={1} />}
                    clearable
                    leftSection={
                      <Group w={"calc(100% - 20px)"} align="center">
                        <Text size="sm" fw={450} c="#44566C">
                          {"Бизнесийн нэр"}
                        </Text>
                        <Text>:</Text>
                      </Group>
                    }
                  />
                </Grid.Col>
              )}
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Select
                  data={[{ code: "", name: "Бүгд" }, ...(invoiceStatus || [])].map((item: any) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  placeholder="сонгох"
                  leftSectionWidth={200}
                  onChange={(e: string | null) => {
                    if (e) setInvoiceType(e);
                  }}
                  value={invoiceType}
                  style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                  rightSection={<IconChevronDown size="1rem" stroke={1} />}
                  clearable
                  leftSection={
                    <Group w={"calc(100% - 20px)"} align="center">
                      <Text size="sm" fw={450} c="#44566C">
                        {"Нэхэмжлэх статус"}
                      </Text>
                      <Text>:</Text>
                    </Group>
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerInput
                  type="range"
                  value={[startDate, endDate]}
                  placeholder="сонгох"
                  leftSectionWidth={100}
                  onChange={(e) => {
                    if (e[0] && e[1]) {
                      setStartDate(e[0] && e[0]);
                      setEndDate(e[1] && e[1]);
                    }
                  }}
                  style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                  rightSection={<IconCalendarDue size="1rem" stroke={1} />}
                  leftSection={
                    <Group w={"calc(100% - 20px)"} align="center">
                      <Text size="sm" fw={450} c="#44566C">
                        {"Огноо"}
                      </Text>
                      <Text>:</Text>
                    </Group>
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Select
                  data={[{ code: "", name: "Бүгд" }, ...(paymentStatus || [])].map((item: any) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  placeholder="сонгох"
                  leftSectionWidth={200}
                  onChange={(e: string | null) => {
                    if (e) setPaymentType(e);
                  }}
                  value={paymentType}
                  style={{ borderRight: "none", borderRadius: "4spx 0 0 4px" }}
                  rightSection={<IconChevronDown size="1rem" stroke={1} />}
                  clearable
                  leftSection={
                    <Group w={"calc(100% - 20px)"} align="center">
                      <Text size="sm" fw={450} c="#44566C">
                        {"Төлбөрийн статус"}
                      </Text>
                      <Text>:</Text>
                    </Group>
                  }
                />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Flex gap="xs">
                  <Select
                    data={[{ code: "", name: "Бүгд" }, ...(paymentTermConditions || [])].map((item: any) => {
                      return {
                        label: item.name,
                        value: item.code,
                      };
                    })}
                    placeholder="сонгох"
                    leftSectionWidth={200}
                    onChange={(e: string | null) => {
                      if (e) setPaymentTermType(e);
                    }}
                    value={paymentTermType}
                    style={{ borderRight: "none", borderRadius: "4px 0 0 4px", flex: 1 }}
                    rightSection={<IconChevronDown size="1rem" stroke={1} />}
                    clearable
                    leftSection={
                      <Group w={"calc(100% - 20px)"} align="center">
                        <Text size="sm" fw={450} c="#44566C">
                          {"Төлбөрийн нөхцөл"}
                        </Text>
                        <Text>:</Text>
                      </Group>
                    }
                  />
                  <ActionIcon
                    onClick={() => {
                      onClear();
                    }}
                    color=""
                    variant="light"
                    size={36}>
                    <IconClearAll />
                  </ActionIcon>
                </Flex>
              </Grid.Col>
            </Grid>
            <Divider color="gray.5" my={20} />
            <CustomTable
              ref={ref}
              colCount={44}
              thead={
                <BaseTable.Thead>
                  <BaseTable.Tr>
                    <BaseTable.Th rowSpan={2}>#</BaseTable.Th>
                    <BaseTable.Th rowSpan={2}>Үйлдэл</BaseTable.Th>
                    <BaseTable.Th
                      className={` ${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={4}>
                      Худалдан авалтын захиалга
                    </BaseTable.Th>
                    <BaseTable.Th
                      className={`${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={2}>
                      Нийлүүлэгч
                    </BaseTable.Th>
                    <BaseTable.Th
                      className={`${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={3}>
                      Хүргэлтийн
                    </BaseTable.Th>
                    <BaseTable.Th
                      className={`${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={2}>
                      Захиалга хүлээн авах
                    </BaseTable.Th>
                    <BaseTable.Th
                      className={`${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={4}>
                      Захиалга баталгаажуулах төлбөрийн
                    </BaseTable.Th>
                    <BaseTable.Th
                      className={`${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={5}>
                      Худалдан авалтын захиалгын
                    </BaseTable.Th>
                    <BaseTable.Th
                      className={`${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={5}>
                      Захиалгын нэхэмжлэх
                    </BaseTable.Th>
                    <BaseTable.Th
                      className={`${classes.control}`}
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={9}>
                      Хүлээлцсэн мэдээлэл
                    </BaseTable.Th>
                  </BaseTable.Tr>
                  <BaseTable.Tr>
                    <BaseTable.Th className={`${classes.control}`}>№</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Огноо, цаг</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Төрөл</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Статус</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Партнер нэр</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Бизнесийн нэр</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Огноо</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Статус</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Хүргэлт хариуцсан</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Салбарын нэр</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Ажилтны нэр</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Дүн</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Огноо, цаг</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Статус</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Нэхэмжлэх №</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Төлбөрийн нөхцөл</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Үнийн дүн</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Төлсөн дүн</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Үлдэгдэл төлбөр</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Төлбөрийн статус</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Нэхэмжлэх №</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Нэхэмжлэх статус</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Баталсан огноо, цаг</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Баталсан ажилтан</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Төлөх огноо цаг</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Бизнес нэр</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Бичсэн ажилтан</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Хянасан ажилтан</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Хянасан огноо цаг</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Хүлээлгэн өгсөн</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Хүлээн авсан</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Хүлээлцсэн огноо цаг</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Зарлагын падаан</BaseTable.Th>
                    <BaseTable.Th className={`${classes.control}`}>Орлогын падаан</BaseTable.Th>
                  </BaseTable.Tr>
                </BaseTable.Thead>
              }
              tbody={(rows: IOrder[]) => {
                return (
                  <BaseTable.Tbody>
                    {(rows || []).map((record, index) => {
                      return (
                        <BaseTable.Tr key={record.id ?? index}>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C">
                              {index + 1}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Flex gap="sm">
                              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0 && (
                                <Tooltip label="Дэлгэрэнгүй" position="bottom">
                                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                                    <IconInfoSquare />
                                  </Button>
                                </Tooltip>
                              )}
                              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
                                <>
                                  {record?.orderStatus === "DRAFT" && (
                                    <Tooltip label="Засварлах" position="bottom">
                                      <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                                        <IconEdit />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </>
                              )}

                              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isReview).length >
                                0 && (
                                <>
                                  {record?.orderStatus === "REGISTERED" && user?.currentBusiness?.id === record?.senderBusiness?.id && (
                                    <Tooltip label="Хянах" position="bottom">
                                      <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("reviewed", record)}>
                                        <IconEyeCheck />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
                                <>
                                  {record?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === record?.receiverBusiness?.id && (
                                    <Tooltip label="Зөвшөөрөх" position="bottom">
                                      <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("confirm", record)}>
                                        <IconChecks />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
                                <>
                                  {record?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === record?.receiverBusiness?.id && (
                                    <Tooltip label="Татгалзах" position="bottom">
                                      <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("reject", record)} color="red">
                                        <IconExclamationMark color="red" />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                              {record?.proformaInvoiceId && (
                                <Tooltip label="Төлбөр батлах" position="bottom">
                                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("payment", record)}>
                                    <IconCreditCard />
                                  </Button>
                                </Tooltip>
                              )}
                            </Flex>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {record?.purchaseCode || "-"}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {dateTimeSecFormat(record?.createdAt)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Badge
                              size="md"
                              radius="sm"
                              variant="outline"
                              style={{
                                border: `1px solid ${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color}`,
                                color: `${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color}`,
                              }}
                              styles={{
                                label: {
                                  overflow: "visible",
                                  textOverflow: "unset",
                                },
                              }}>
                              {orderPurchaseTypes?.find((c: any) => c?.code === record?.purchaseType)?.name || "-"}
                            </Badge>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            {record?.type === "SALES" ? (
                              <Badge
                                size="md"
                                radius="sm"
                                variant="outline"
                                style={{
                                  border: `1px solid ${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                                  color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                                }}
                                styles={{
                                  label: {
                                    overflow: "visible",
                                    textOverflow: "unset",
                                  },
                                }}>
                                {orderStatus.find((c: any) => c.code === record.orderStatus)?.receivedName || "-"}
                              </Badge>
                            ) : (
                              <Badge
                                size="md"
                                radius="sm"
                                variant="outline"
                                style={{
                                  border: `1px solid ${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                                  color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                                }}
                                styles={{
                                  label: {
                                    overflow: "visible",
                                    textOverflow: "unset",
                                  },
                                }}>
                                {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName ?? "-"}
                              </Badge>
                            )}
                          </BaseTable.Td>
                          <BaseTable.Td>
                            {record?.type === "PURCHASE" ? (
                              <div>
                                <Text size="sm" fw={500} c="#44566C" w="max-content">
                                  {record?.receiverBusiness?.partner?.businessName ?? "-"}
                                </Text>
                                <Text size="sm" fw={500} c="cyan" w="max-content">
                                  {record?.receiverBusiness?.partner?.refCode ?? "-"}
                                </Text>
                              </div>
                            ) : (
                              <div>
                                <Text size="sm" fw={500} c="#44566C" w="max-content">
                                  {record?.senderBusiness?.partner?.businessName ?? "-"}
                                </Text>
                                <Text size="sm" fw={500} c="cyan" w="max-content">
                                  {record?.senderBusiness?.partner?.refCode ?? "-"}
                                </Text>
                              </div>
                            )}
                          </BaseTable.Td>
                          <BaseTable.Td>
                            {record?.type === "PURCHASE" ? (
                              <div>
                                <Text size="sm" fw={500} c="#44566C" w="max-content">
                                  {record?.receiverBusiness?.profileName ?? "-"}
                                </Text>
                                <Text size="sm" fw={500} c="cyan" w="max-content">
                                  {record?.receiverBusiness?.refCode ?? "-"}
                                </Text>
                              </div>
                            ) : (
                              <div>
                                <Text size="sm" fw={500} c="#44566C" w="max-content">
                                  {record?.senderBusiness?.profileName ?? "-"}
                                </Text>
                                <Text size="sm" fw={500} c="cyan" w="max-content">
                                  {record?.senderBusiness?.refCode ?? "-"}
                                </Text>
                              </div>
                            )}
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {dateTimeSecFormat(record?.deliveryDate)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Badge
                              size="md"
                              radius="sm"
                              variant="outline"
                              style={{
                                border: `1px solid ${deliveryNoteStatus.find((c: any) => c.code === record?.deliveryNote?.deliveryNoteStatus)?.color}`,
                                color: `${deliveryNoteStatus.find((c: any) => c.code === record?.deliveryNote?.deliveryNoteStatus)?.color}`,
                              }}
                              styles={{
                                label: {
                                  overflow: "visible",
                                  textOverflow: "unset",
                                },
                              }}>
                              {deliveryNoteStatus.find((c: any) => c?.code === record?.deliveryNote?.deliveryNoteStatus)?.name ?? "-"}
                            </Badge>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Group>
                              <div>
                                <Text size="sm" fw={500} c="#44566C">
                                  {renderName(record?.deliveryNote?.staff)}
                                </Text>
                                <Flex gap={5} wrap="nowrap">
                                  <Text size="sm" fw={500} c="cyan">
                                    {record?.deliveryNote?.staff?.email ?? "-"}
                                  </Text>
                                </Flex>
                              </div>
                            </Group>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {record?.receiverBranch?.name ?? "-"}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {renderName(record?.receiverStaff)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" ta="end" style={{ whiteSpace: "nowrap" }}>
                              {tugrug(record?.orderAmount ?? 0)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {dateTimeSecFormat(record?.proformaInvoice?.confirmedDate)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Badge
                              size="md"
                              radius="sm"
                              variant="outline"
                              style={{
                                border: `1px solid ${paymentStatus.find((c: any) => c.code === record?.proformaInvoice?.paymentStatus)?.color}`,
                                color: `${paymentStatus.find((c: any) => c.code === record?.proformaInvoice?.paymentStatus)?.color}`,
                              }}
                              styles={{ label: { overflow: "visible" } }}>
                              {paymentStatus.find((c: any) => c.code === record?.proformaInvoice?.paymentStatus)?.name ?? "-"}
                            </Badge>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {record?.proformaInvoice?.refCode ?? "-"}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {record?.paymentTerm?.description ?? "-"}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" ta="end" style={{ whiteSpace: "nowrap" }}>
                              {tugrug(record?.totalAmount ?? 0)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" ta="end" style={{ whiteSpace: "nowrap" }}>
                              {tugrug(record?.paidAmount ?? 0)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" ta="end" style={{ whiteSpace: "nowrap" }}>
                              {tugrug(record?.amountToPay ?? 0)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Badge
                              size="md"
                              radius="sm"
                              variant="outline"
                              style={{
                                border: `1px solid ${paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.color}`,
                                color: `${paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.color}`,
                              }}
                              styles={{
                                label: {
                                  overflow: "visible",
                                },
                              }}>
                              {paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.name ?? "-"}
                            </Badge>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {record?.invoice?.refCode ?? "-"}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Badge
                              size="md"
                              radius="sm"
                              variant="outline"
                              style={{
                                border: `1px solid ${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color}`,
                                color: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color}`,
                              }}
                              styles={{
                                label: {
                                  overflow: "visible",
                                },
                              }}>
                              {invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.name ?? "-"}
                            </Badge>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {dateTimeSecFormat(record?.invoice?.confirmedDate)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <div>
                              <Text size="sm" fw={500} c="#44566C" w="max-content">
                                {renderName(record?.invoice?.confirmedUser)}
                              </Text>
                              <Text size="sm" fw={500} c="cyan" w="max-content">
                                {record?.invoice?.confirmedUser?.email ?? "-"}
                              </Text>
                            </div>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {dateTimeSecFormat(record?.invoice?.paymentDate)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <div>
                              <Text size="sm" fw={500} c="#44566C" w="max-content">
                                {record?.receiverBusiness?.refCode ?? "-"}
                              </Text>
                              <Text size="sm" fw={500} c="cyan" w="max-content">
                                {record?.receiverBusiness?.profileName ?? "-"}
                              </Text>
                            </div>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <div>
                              <Text size="sm" fw={500} c="#44566C" w="max-content">
                                {renderName(record?.orderedUser)}
                              </Text>
                              <Text size="sm" fw={500} c="cyan" w="max-content">
                                {record?.orderedUser?.phone ?? "-"}
                              </Text>
                            </div>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {renderName(record?.reviewedUser)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {dateTimeSecFormat(record?.reviewedDate)}
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <div>
                              <Text size="sm" fw={500} c="#44566C" w="max-content">
                                {renderName(record?.deliveryNote?.staff)}
                              </Text>
                              <Text size="sm" fw={500} c="cyan" w="max-content">
                                {record?.deliveryNote?.staff?.phone ?? "-"}
                              </Text>
                            </div>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <div>
                              <Text size="sm" fw={500} c="#44566C" w="max-content">
                                {renderName(record?.receiverStaff)}
                              </Text>
                              <Text size="sm" fw={500} c="cyan" w="max-content">
                                {record?.receiverStaff?.phone ?? "-"}
                              </Text>
                            </div>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C">
                              -
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C">
                              -
                            </Text>
                          </BaseTable.Td>
                          <BaseTable.Td>
                            <Text size="sm" fw={500} c="#44566C">
                              -
                            </Text>
                          </BaseTable.Td>
                        </BaseTable.Tr>
                      );
                    })}
                  </BaseTable.Tbody>
                );
              }}
              name="buyer-sales.list"
              filters={{
                type: "",
                orderStatus: debouncedOrderType,
                businessId: debouncedBusiness,
                startDate: debouncedStartDate,
                endDate: debouncedEndDate,
                invoiceStatus: debouncedInvoiceType,
                paymentStatus: debouncedPaymentType,
                paymentTermCondition: debouncedPaymentTermType,
                excel: `${false}`,
              }}
              loadData={(data) => OrderApi.list(data!)}
            />
          </Box>
        </Flex>
      </PageLayout>

      <Modal
        opened={action[0] === "PAYMENT"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="80%"
        padding={0}
        centered>
        <BuyerSalesPaymentForm action={action} setAction={setAction} reload={reload} />
      </Modal>
      <Modal
        opened={action[0] === "confirm" || action[0] === "reject"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <BuyerSalesDynamicConfirmForm action={action && action[1]} setActions={setAction} constant={action && action[2]} reload={reload} />
      </Modal>

      <Modal
        opened={action[0] === "reviewed"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <BuyerSalesDynamicReviewForm action={action && action[1]} setActions={setAction} reload={reload} />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Худалдан авалт",
  },
];

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
  },
  table: {
    minWidth: 1000,
  },
  th: {
    padding: "8px!important",
    lineHeight: "14px",
    whiteSpace: "nowrap",
  },
  td: {
    padding: "8px!important",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    whiteSpace: "nowrap",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));
