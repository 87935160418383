import { Avatar, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import { IconFile, IconInfoSquare, IconPhoto } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PullSheetApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IPullSheet } from "../../interfaces/IPullSheet";
import { IReference } from "../../models/General";
import { dateTimeSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";

export function SupplierShippingCustomerList() {
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { pullSheetStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({
    pullSheetStatus,
    user,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          return navigate(`/supplier-shipping/detail/${record.id}`);
        }
        default:
      }
    },
  });

  return (
    <Table
      name="supplier-shipping-customer.list"
      filters={{
        pullSheetStatus: "",
      }}
      columns={columns}
      loadData={(data) => PullSheetApi.list(data!)}
    />
  );
}

const useHeader = ({
  onClick,
  user,
  pullSheetStatus,
}: {
  onClick: (key: string, record: IPullSheet) => void;
  pullSheetStatus: IReference[];
  user: any;
}): ColumnType<IPullSheet>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions ?? []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_PS_LIST").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => {}} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
          {record?.pullSheetStatus === "CONFIRMED" && (
            <Tooltip label="Падаан харах">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => window.open(record?.pdf, "_blank", "noopener,noreferrer")}>
                <IconFile />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Ачилт падаан №",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.refCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Ачилт хийх огноо",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.loadingDate) ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Ачилт статус",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `1px solid ${pullSheetStatus.find((c: any) => c.code === record.pullSheetStatus)?.color}`,
            color: `${pullSheetStatus.find((c: any) => c.code === record.pullSheetStatus)?.color}`,
          }}
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {pullSheetStatus.find((c: any) => c?.code === record?.pullSheetStatus)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүргэх огноо",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.deliveryDate) ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүргэлт хариуцсан",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <Flex gap={10} align="center">
          <Avatar src={record?.staff?.avatar ?? noimage}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm" c="#44566C" w="max-content">
              {renderName(record.staff)}
            </Text>
            <Text size="sm" c="#343A40" w="max-content">
              {record?.staff?.email ?? "-"}
            </Text>
          </div>
        </Flex>
      );
    },
  },
  {
    title: "Захиалгын тоо",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.deliveryNoteCount ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Агуулах нэр",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.warehouse?.refCode ?? "-"}, {record?.warehouse?.name ?? "-"}
        </Text>
      );
    },
  },
];
