import { LoadingOverlay } from "@mantine/core";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { Layout } from "./components/layout";
import { SupplierDeliveryDistributingForm } from "./components/supplier-delivery-distributing/form";
import { IAuth } from "./interfaces/IAuth";
import { Access } from "./pages/access";
import CreatePage from "./pages/auth/create";
import { BuyerCustomerDetail } from "./pages/buyer-customer/detail";
import { BuyerCustomerList } from "./pages/buyer-customer/list";
import { BuyerCustomerOrder } from "./pages/buyer-customer/order";
import { BuyerSalesDetail } from "./pages/buyer-sales/detail";
import { BuyerSalesEdit } from "./pages/buyer-sales/edit";
import { BuyerSalesList } from "./pages/buyer-sales/list";
import { BuyerSalesNew } from "./pages/buyer-sales/new";
import { Dashboard } from "./pages/dashboard";
import { SupplierDropShippingTabs } from "./pages/drop-shipping";
import { SupplierPurchaseTabs } from "./pages/purchase-sales";
import { PurchaseSalesDetail } from "./pages/purchase-sales/detail";
import { SupplierCustomerDetail } from "./pages/supplier-customer/detail";
import { SupplierCustomerList } from "./pages/supplier-customer/list";
import { SupplierCustomerOrder } from "./pages/supplier-customer/order";
import { SupplierDeliveryTabs } from "./pages/supplier-delivery";
import { SupplierSalesTabs } from "./pages/supplier-sales";
import { SupplierSalesDetail } from "./pages/supplier-sales/detail";
import { SupplierSalesEdit } from "./pages/supplier-sales/edit";
import { SupplierSalesNew } from "./pages/supplier-sales/new";
import { SupplierShippingTab } from "./pages/supplier-shipping";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";
import { message } from "./utils/message";

import { showNotification } from "@mantine/notifications";
import { SupplierDeliveryDistributingDetail } from "./components/supplier-delivery-distributing/detail";
import { SupplierDropShippingDetail } from "./components/supplier-dropshipping/detail";
import { SupplierShippingCustomerDetail } from "./components/supplier-shipping-customer/detail";
import { onMessageListener, requestForToken } from "./firebase/fcmService";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const hasRequestedToken = useRef(false);

  const { accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  useEffect(() => {
    if (!hasRequestedToken.current) {
      requestForToken();
      hasRequestedToken.current = true;
    }

    onMessageListener((payload: any) => {
      showNotification({
        id: `notif-${Math.random()}`,
        title: payload?.notification?.title || "New Notification",
        message: payload?.notification?.body || "You have received a new notification.",
        color: "blue",
        autoClose: 5000,
      });
    });
  }, []);

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken) {
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));

          const auth = await AuthApi.me();
          dispatch(authMe(auth));
        }
        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <Route path="/register" element={<CreatePage />} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/access" element={<Access />} />
        {/* BUYER */}
        <Route path="/buyer-sales" element={<BuyerSalesList />} />
        <Route path="/buyer-sales/new" element={<BuyerSalesNew />} />
        <Route path="/buyer-sales/detail/:id" element={<BuyerSalesDetail />} />
        <Route path="/buyer-sales/edit/:id" element={<BuyerSalesEdit />} />

        <Route path="/buyer-customer" element={<BuyerCustomerList />} />
        <Route path="/buyer-customer/order/:id" element={<BuyerCustomerOrder />} />
        <Route path="/buyer-customer/detail/:id" element={<BuyerCustomerDetail />} />

        {/* SUPPLIER */}
        <Route path="/supplier-sales" element={<SupplierSalesTabs />} />
        <Route path="/supplier-sales/new" element={<SupplierSalesNew />} />
        <Route path="/supplier-sales/edit/:id/:receiverBusinessId" element={<SupplierSalesEdit />} />
        <Route path="/supplier-sales/detail/:id" element={<SupplierSalesDetail />} />

        <Route path="/supplier-drop-shipping" element={<SupplierDropShippingTabs />} />
        <Route path="/supplier-drop-shipping/detail/:id" element={<SupplierDropShippingDetail />} />
        <Route path="/supplier-drop-shipping/:type/detail/:id" element={<SupplierDropShippingDetail />} />

        <Route path="/supplier-purchase" element={<SupplierPurchaseTabs />} />
        <Route path="/supplier-purchase/detail/:id" element={<PurchaseSalesDetail />} />

        <Route path="/supplier-customer" element={<SupplierCustomerList />} />
        <Route path="/supplier-customer/detail/:id" element={<SupplierCustomerDetail />} />
        <Route path="/supplier-customer/order/:id" element={<SupplierCustomerOrder />} />

        <Route path="/supplier-delivery" element={<SupplierDeliveryTabs />} />
        <Route path="/supplier-delivery/approve" element={<SupplierDeliveryDistributingForm />} />
        <Route path="/supplier-delivery/detail/:id" element={<SupplierDeliveryDistributingDetail />} />

        <Route path="/supplier-shipping" element={<SupplierShippingTab />} />
        <Route path="/supplier-shipping/detail/:id" element={<SupplierShippingCustomerDetail />} />
      </Route>
    </Routes>
  );
}

export default App;
