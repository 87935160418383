import { ActionIcon, Alert, Avatar, Badge, Box, Button, Divider, Flex, Grid, Group, Modal, Text } from "@mantine/core";
import { IconDeviceFloppy, IconEdit, IconPercentage, IconPhoto, IconTextPlus, IconTrash } from "@tabler/icons-react";
import React from "react";
import noimage from "../../assets/no-image.png";
import { currencyFormat } from "../../utils";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { ColumnType, Table } from "../table";

import { IAdditionalLines } from "../../interfaces/IAdditionalLines";
import { IAttachments } from "../../interfaces/IAttachments";
import { dateTimeSecFormat } from "../../utils/date";
import RenderText from "../ui/render-text";
import { SupplierSalesAddDynamicAdditionalForm } from "./add-dynamic-additional-form";
import { SupplierSalesAddDynamicPackageForm } from "./add-dynamic-package-form";

type Props = {
  values?: any;
  setFieldValue?: any;
  errors?: any;
};

export function SupplierSalesAddDynamicForm({ values, setFieldValue, errors }: Props) {
  const now_date = new Date();
  const [edit, setEdit] = React.useState<string>("");
  const [action, setAction] = React.useState<string[]>([]);
  const columns = useHeader({
    edit,
    setEdit,
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue("lines", [...values.lines.filter((i: any, index: number) => item.index !== index)]);
      } else if (key === "edit") {
        setAction(["edit", item]);
      }
    },
  });

  const columnsAdditional = useHeaderAdditional({
    edit,
    setEdit,
    setFieldValue,
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue("additionalLines", [...values.additionalLines.filter((i: any, index: number) => item.index !== index)]);
      } else if (key === "edit") {
        setAction(["edit", item]);
      }
    },
  });

  const columnsAttachments = useHeaderAttachments({
    edit,
    setEdit,
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue("attachments", [...values.attachments.filter((i: any, index: number) => item.index !== index)]);
      } else if (key === "edit") {
        setAction(["edit", item]);
      }
    },
  });

  const len = Object.keys(values?.lines || [])?.length;

  return (
    <Box
      sx={(theme) => ({
        background: theme.white,
        borderRadius: 5,
        border: `1px solid ${theme.colors.gray[2]}`,
        padding: "0px 20px",
      })}>
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          Бараа бүтээгдэхүүн, ажил үйлчилгээ
        </Text>
        <Flex gap="md" align="center">
          <ActionIcon variant="outline" size="md" color="#3BC9DB">
            <IconTextPlus onClick={() => setAction(["add", values])} />
          </ActionIcon>
          <Button variant="outline" size="xs" onClick={() => setAction(["package", values])} color="#3BC9DB">
            Багцаар нэмэх
          </Button>
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table columns={columns} name="values.lines" dataSource={values.lines} pagination={false} />
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          Нэмэлтээр
        </Text>
        <Flex gap="md" align="center">
          <ActionIcon variant="outline" size="md" color="#3BC9DB">
            <IconTextPlus
              onClick={() => {
                setFieldValue("additionalLines", [
                  ...(values?.additionalLines || []),
                  {
                    name: "",
                    unit: "",
                    price: 0,
                    quantity: 0,
                    discountType: "",
                    discountValue: 0,
                    isEdit: true,
                    isNew: true,
                  },
                ]);
              }}
            />
          </ActionIcon>
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table columns={columnsAdditional} name="values.additionalLines" dataSource={values.additionalLines} pagination={false} />
      <Divider color="gray" my="xs" />
      <Alert color="red" title={errors.items} withCloseButton hidden={!errors.items}>
        Та бараа оруулна уу.
      </Alert>
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          Захиалгын нэгтгэл
        </Text>
      </Flex>
      <Divider color="gray" my="xs" />
      <Grid mb={5}>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Grid>
            <Grid.Col span={12}>
              <TextareaField name="senderNote" label="Харилцагчийн нэмэлт мэдээлэл" placeholder="Тайлбар оруулах: " max={255} />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextareaField name="senderAdditionalNote" label="Нэмэлт тэмдэглэл" placeholder="Тэмдэглэл оруулах: " max={255} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Box
            h="100%"
            sx={(theme) => ({
              backgroundColor: theme.white,
              borderLeft: `2px outset #5fc6d9`,
            })}
            px={20}
            py={16}>
            <Grid>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгад тоо
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {len} төрөл
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Нийт тоо ширхэг
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {values?.lines?.reduce((total: number, item: any) => total + item!.quantity || 0, 0) || 0} ширхэг
                </Text>
              </Grid.Col>

              <Grid.Col span={12}>
                <Text size="lg" fw={500} c="#25262B">
                  {"Тооцоолол"}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгын нийт дүн
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НӨАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НХАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Тооцсон хөнгөлөлт
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(
                    (values?.lines || [])?.reduce(
                      (total: number, item: any) =>
                        total +
                          (item?.discountType === "PERCENTAGE"
                            ? (item?.price * item?.quantity * item?.discountValue) / 100
                            : item?.quantity * item?.discountValue) || 0,
                      0,
                    ) || 0,
                  )}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Хүргэлтийн төлбөр
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <NumberCurrencyField max={1000000000} name="shippingAmount" label="" placeholder="0.00" required />
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Үнийн дүнгийн хөнгөлөлт
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Flex gap={8}>
                  {values.discountType === "PERCENTAGE" ? (
                    <NumberField
                      w={"80%"}
                      max={100}
                      disabled={!values.discountType}
                      name="discountValue"
                      label=""
                      placeholder="0.00"
                      icon={<IconPercentage size={18} stroke="1.5" color="gray" opacity={0.8} />}
                      required
                    />
                  ) : (
                    <NumberCurrencyField w={"80%"} max={1000000000} disabled={!values.discountType} name="discountValue" label="" placeholder="0.00" required />
                  )}
                  <SelectField
                    label=""
                    onChange={() => {
                      setFieldValue("discountValue", undefined);
                    }}
                    noError
                    name="discountType"
                    options={[
                      { label: "Хувь", value: "PERCENTAGE" },
                      { label: "Дүн", value: "AMOUNT" },
                    ]}
                    placeholder="Сонгох"
                  />
                </Flex>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  {"Нэмэлтийн нийт дүн"}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {!values?.additionalLines
                    ? currencyFormat(0)
                    : currencyFormat(
                        (values?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                          (values?.additionalLines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? ((item?.price || 0) * (item?.quantity || 0) * item.discountValue) / 100
                                  : item?.discountValue) || 0,
                            0,
                          ),
                      )}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  {"Нийт дүн"}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {!values?.lines
                    ? currencyFormat(0)
                    : currencyFormat(
                        values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (values?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          (values?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                          (values?.additionalLines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? ((item?.price || 0) * (item?.quantity || 0) * item?.discountValue || 0) / 100
                                  : item?.discountValue) || 0,
                            0,
                          ) +
                          (values?.shippingAmount || 0) -
                          (values?.discountType === "PERCENTAGE"
                            ? ((values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                                (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                                (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                                (values?.lines || [])?.reduce(
                                  (total: number, item: any) =>
                                    total +
                                      (item?.discountType === "PERCENTAGE"
                                        ? (item?.price * item?.quantity * item?.discountValue) / 100
                                        : item?.quantity * item?.discountValue) || 0,
                                  0,
                                ) +
                                (values?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                                (values?.additionalLines || [])?.reduce(
                                  (total: number, item: any) =>
                                    total +
                                      (item?.discountType === "PERCENTAGE"
                                        ? ((item?.price || 0) * (item?.quantity || 0) * item?.discountValue || 0) / 100
                                        : item?.discountValue || 0) || 0,
                                  0,
                                ) +
                                (values?.shippingAmount || 0)) *
                                (values?.discountValue || 0)) /
                              100
                            : values?.discountValue || 0),
                      )}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                {!values?.lines || !values?.paymentTerm ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(0)}
                  </Text>
                ) : values?.paymentTerm?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(
                      ((values?.discountType === "AMOUNT"
                        ? values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (values?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          values?.shippingAmount -
                          values?.discountValue
                        : values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (values?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          values?.shippingAmount -
                          ((values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                            (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                            (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                            (values?.lines || [])?.reduce(
                              (total: number, item: any) =>
                                total +
                                  (item?.discountType === "PERCENTAGE"
                                    ? (item?.price * item?.quantity * item?.discountValue) / 100
                                    : item?.quantity * item?.discountValue) || 0,
                              0,
                            ) +
                            values?.shippingAmount) *
                            values?.discountValue) /
                            100) *
                        values?.paymentTerm.advancePercent) /
                        100,
                    )}
                  </Text>
                ) : (
                  "-"
                )}
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах огноо, цаг
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                {values?.paymentTerm?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {dateTimeSecFormat(now_date.setDate(now_date.getDate() + values?.paymentTerm?.confirmationDay))}
                  </Text>
                ) : (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {"-"}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Хавсралт нэмэх"}
        </Text>
        <Flex gap="md" align="center">
          <ActionIcon variant="outline" size="md" color="#3BC9DB">
            <IconTextPlus
              onClick={() => {
                setFieldValue("attachments", [
                  ...(values?.attachments || []),
                  {
                    name: "",
                    unit: "",
                    price: 0,
                    quantity: 0,
                    discountType: "",
                    discountValue: 0,
                    isEdit: true,
                    isNew: true,
                  },
                ]);
              }}
            />
          </ActionIcon>
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table columns={columnsAttachments} name="values.attachments" dataSource={values.attachments} pagination={false} />
      <Divider color="gray" my="xs" />
      <Modal
        opened={action[0] === "package" && values?.supplierId}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="1200px"
        padding={0}
        centered>
        <SupplierSalesAddDynamicPackageForm values={values} setFieldValue={setFieldValue} setAction={setAction} />
      </Modal>
      <Modal
        opened={action[0] === "add" && values?.supplierId}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="900px"
        padding={0}
        centered>
        <SupplierSalesAddDynamicAdditionalForm values={values} action={action} setFieldValue={setFieldValue} setAction={setAction} />
      </Modal>
    </Box>
  );
}

const useHeader = ({ edit, setEdit, onClick }: { edit: any; setEdit: any; onClick: (e: any, e1: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar size={40} src={record?.image !== null ? record?.image : noimage} radius={5}>
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => <RenderText text={record?.skuCode || "-"} />,
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "nameApp",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextField w={320} name={`lines[${index}].nameApp`} placeholder="Бараа, ажил үйлчилгээ" />;
      } else return <RenderText text={record?.nameApp || "-"} />;
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => <RenderText text={record?.unit?.name || record?.unit || "-"} />,
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    titleAlign: "right",
    render: (record, index) => {
      if (record.isEdit) return <NumberCurrencyField name={`lines[${index}].price`} placeholder="0.00" />;
      else return <RenderText text={currencyFormat(record?.price || 0)} isAmount />;
    },
  },
  {
    title: "НӨАТ",
    dataIndex: "hasVat",
    titleAlign: "right",
    render: (record) => <RenderText text={currencyFormat(record?.vatAmount)} isAmount />,
  },
  // {
  //   title: "Хөнгөлөлт",
  //   dataIndex: "discountValue",
  //   render: (record, index) => {
  //     let count;
  //     count =
  //       record?.discountType === "PERCENTAGE"
  //         ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
  //         : (record?.quantity || 0) * record?.discountValue;
  //     return (
  //       <Text size="sm" fw={500} c="#44566C">
  //         {currencyFormat(count || 0)}
  //       </Text>
  //     );
  //   },
  // },
  {
    title: "Захиалах тоо",
    dataIndex: "quantity",
    render: (record, index) => {
      return (
        <Flex align="center" gap="xs">
          {edit === index ? (
            <NumberField max={9000000} min={0} size="xs" name={`lines[${index}].quantity`} placeholder="Тоо хэмжээ" />
          ) : (
            <Badge color="cyan" size="md" radius="sm" variant="outline">
              {record.quantity || 0}
            </Badge>
          )}
          <ActionIcon onClick={() => setEdit(edit !== index ? index : null)}>{edit === index ? <IconDeviceFloppy /> : <IconEdit />}</ActionIcon>
        </Flex>
      );
    },
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    titleAlign: "right",
    render: (record) => <RenderText text={currencyFormat(record?.taxAmount)} isAmount />,
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    titleAlign: "right",
    render: (record: any) => {
      let count;
      count =
        (record?.price || 0) * (record?.quantity || 0) +
        (record?.quantity || 0) * (record?.vatAmount || 0) -
        (record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue);
      return <RenderText text={currencyFormat(count || 0)} isAmount />;
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    titleAlign: "right",
    render: (record, index) => {
      return (
        <ActionIcon onClick={() => onClick("remove", { ...record, index })} color="red" variant="light">
          <IconTrash />
        </ActionIcon>
      );
    },
  },
];

const useHeaderAdditional = ({
  edit,
  setEdit,
  onClick,
  setFieldValue,
}: {
  edit: any;
  setEdit: any;
  setFieldValue: any;
  onClick: (e: any, e1: any) => void;
}): ColumnType<IAdditionalLines>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Зүйлийн нэр тайлбар",
    dataIndex: "name",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextField w={320} size="xs" name={`additionalLines[${index}].name`} placeholder="нэр оруулах" />;
      } else return <RenderText text={record?.nameApp || "-"} />;
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextField w={320} size="xs" name={`additionalLines[${index}].unit`} placeholder="нэгж" />;
      } else return <RenderText text={record?.nameApp || "-"} />;
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record, index) => {
      if (record.isEdit) return <NumberCurrencyField max={9999999999} size="xs" name={`additionalLines[${index}].price`} placeholder="0.00" />;
      else return <RenderText text={currencyFormat(record?.price || 0)} isAmount />;
    },
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "quantity",
    render: (record, index) => {
      if (record.isEdit) return <NumberField max={9000000} size="xs" min={0} name={`additionalLines[${index}].quantity`} placeholder="0" />;
      else
        return (
          <Flex align="center" gap="xs">
            {edit === index ? (
              <NumberField max={9000000} min={0} size="xs" name={`additionalLines[${index}].quantity`} placeholder="Тоо хэмжээ:" />
            ) : (
              <Badge color="cyan" size="md" radius="sm" variant="outline">
                {record.quantity || "0"}
              </Badge>
            )}
            <ActionIcon onClick={() => setEdit(edit !== index ? index : null)}>{edit === index ? <IconDeviceFloppy /> : <IconEdit />}</ActionIcon>
          </Flex>
        );
    },
  },
  {
    title: "Хөнгөлөлт төрөл",
    dataIndex: "discountType",
    render: (record, index) => {
      if (record.isEdit)
        return (
          <SelectField
            label=""
            onChange={() => {
              setFieldValue("discountValue", undefined);
            }}
            noError
            name={`additionalLines[${index}].discountType`}
            size="xs"
            options={[
              { label: "Хувь", value: "PERCENTAGE" },
              { label: "Дүн", value: "AMOUNT" },
            ]}
            placeholder="Сонгох"
          />
        );
      else
        return (
          <Group align="center">
            {edit === index ? (
              <SelectField
                label=""
                onChange={() => {
                  setFieldValue("discountValue", undefined);
                }}
                noError
                name={`additionalLines[${index}].discountType`}
                size="xs"
                options={[
                  { label: "Хувь", value: "PERCENTAGE" },
                  { label: "Дүн", value: "AMOUNT" },
                ]}
                placeholder="Сонгох"
              />
            ) : (
              <Text w={150}>
                <Badge color="cyan" size="md" radius="sm" variant="outline">
                  {record.discountType === "PERCENTAGE" && "Хувь"}
                  {record.discountType === "AMOUNT" && "Дүн"}
                  {!record?.discountType && "-"}
                </Badge>
              </Text>
            )}
          </Group>
        );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountType",
    render: (record, index) => {
      return (
        <>
          {record?.discountType === "AMOUNT" && (
            <NumberCurrencyField max={1000000000} name={`additionalLines[${index}].discountValue`} label="" placeholder="0.00" required />
          )}
          {record?.discountType === "PERCENTAGE" && (
            <NumberField
              max={100}
              name={`additionalLines[${index}].discountValue`}
              label=""
              placeholder="0.00"
              icon={<IconPercentage size={18} stroke="1.5" color="gray" opacity={0.8} />}
              required
            />
          )}
          {!record?.discountType && (
            <NumberCurrencyField max={1000000000} name={`additionalLines[${index}].discountValue`} disabled label="" placeholder="0.00" required />
          )}
        </>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    titleAlign: "right",
    render: (record: any) => {
      let count;
      count =
        (record?.price || 0) * (record?.quantity || 0) -
        (record?.discountType === "PERCENTAGE" ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100 : record?.discountValue);

      return <RenderText text={currencyFormat(count || 0)} isAmount />;
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    titleAlign: "right",
    render: (record, index) => {
      return (
        <ActionIcon onClick={() => onClick("remove", { ...record, index })} color="red" variant="light">
          <IconTrash />
        </ActionIcon>
      );
    },
  },
];

const useHeaderAttachments = ({ edit, setEdit, onClick }: { edit: any; setEdit: any; onClick: (e: any, e1: any) => void }): ColumnType<IAttachments>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextField w={320} size="xs" name={`attachments[${index}].name`} placeholder="нэр:" />;
      } else return <RenderText text={record?.name || "-"} />;
    },
  },
  {
    title: "Урл",
    dataIndex: "url",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextField w={320} name={`attachments[${index}].url`} placeholder="урл:" />;
      } else return <RenderText text={record?.url || "-"} />;
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextareaField name={`attachments[${index}].description`} placeholder="Тайлбар:" label="" max={255} />;
      } else return <RenderText text={record?.description || "-"} />;
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    titleAlign: "right",
    render: (record, index) => {
      return (
        <ActionIcon onClick={() => onClick("remove", { ...record, index })} color="red" variant="light">
          <IconTrash />
        </ActionIcon>
      );
    },
  },
];
